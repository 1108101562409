<template>
        <div class="form">
            <div class="bg-col">
                <div class="dy-text-box">
                    <input placeholder='请输入标题' name="title" v-model="title" />
                </div>
                <div class="linename"></div>
                <div class="dy-text-box">
                        <textarea placeholder='请输入动态内容' name="detailspage" v-model="message" ></textarea>
                </div>
                <div class="linename"></div>
                <div class="product_uploader">
                    <Uploader v-model="fileList" :after-read="afterRead" :before-read="beforeRead" :max-size="10000 * 1024" :max-count="1" @oversize="onOversize" />
                </div>
            </div>
                <div class="btn">
                    <!-- <button class="anniu" @click="dtsave" ><span>保存</span></button> -->
                    <button class="anniu fa" @click="dtsubmit"><span>提交</span></button>
                </div>
        </div>
</template>
<script>
import { uploadImg,dtRelease } from "@api/tasting";
import { Icon, Uploader , Toast } from 'vant';
import { Image as VanImage } from 'vant';

export default {
        name: "add",
        components: {
             Icon, Uploader , Toast ,VanImage
        },
        data: function() {
            return {
                fileList: [],
                title:'',
                message:'',
                id:0
            };
        },
        created: function() {
            this.id=this.$route.query.id;
        },  
        mounted: function() {
            this.getRelease();
        },
        methods: {
        getRelease:function(){
            if(this.id){
                dtRelease({
                    id: this.id, 
                    type:0
                }).then(res => {
                    this.title = res.data.title;
                    this.message = res.data.detailspage;
                    this.fileList[0]={'url':res.data.images};
                    console.log(this.fileList);
                })
            }
        }, 
        //保存
        // dtsave(){
        //     if(!this.title){
        //         Toast.fail({
        //             message: '请填写标题',
        //             forbidClick: true,
        //         });
        //         return
        //     }
        //     if(!this.message){
        //         Toast.fail({
        //             message: '内容不能为空',
        //             forbidClick: true,
        //         });
        //         return
        //     }
        //     if(this.fileList.length==0){
        //         Toast.fail({
        //             message: '请上传图片',
        //             forbidClick: true,
        //         });
        //         return
        //     }
        //     let image=[];

        //     this.fileList.forEach(function(item) {
        //         image.push(item.url)
        //     });
        //     Toast.loading({
        //         duration: 0,
        //         message: '发布中...',
        //         forbidClick: true,
        //     });
        //     dtRelease({
        //         id: this.id, //id
        //         title:this.title,
        //         detailspage: this.message, //内容
        //         images: image, //图片集合
        //     }).then(res => {
        //         Toast.success({
        //             message: res.msg,
        //             forbidClick: true,
        //         });
        //         if(this.showdraft){
        //             cookie.remove('draft');
        //         }
        //         var that = this;
        //         setTimeout(function(){ 
        //             that.$router.back(-1);
        //         }, 2000);
        //     })
        //     .catch(err => {
        //         Toast.fail({
        //             message: err.msg,
        //             forbidClick: true,
        //         });
        //     });
        // },
        //提交
        dtsubmit(){
            if(!this.title){
                Toast.fail({
                    message: '请填写标题',
                    forbidClick: true,
                });
                return
            }
            if(!this.message){
                Toast.fail({
                    message: '内容不能为空',
                    forbidClick: true,
                });
                return
            }
            if(this.fileList.length==0){
                Toast.fail({
                    message: '请上传图片',
                    forbidClick: true,
                });
                return
            }
            let image=[];
            this.fileList.forEach(function(item) {
                image.push(item.url)
            });
            Toast.loading({
                duration: 0,
                message: '发布中...',
                forbidClick: true,
            });
            dtRelease({
                id: this.id, //id
                title:this.title,
                detailspage: this.message, //内容
                images: image, //图片集合
            }).then(res => {
                Toast.success({
                    message: res.msg,
                    forbidClick: true,
                });
                var that = this;
                setTimeout(function(){ 
                    that.$router.back(-1);
                }, 2000);
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file);
            let index = this.fileList.length - 1;
            file.status = 'uploading';
            file.message = '上传中...';
            var formData = new FormData();
　　        formData.append('file', file.file);
            Toast.loading({
                duration: 0,
                message: '上传中',
                forbidClick: true,
            });
            uploadImg(formData).then(res => {
                Toast.clear();
                this.fileList[index] = res.data;
                file.status = '';
                file.message = '';
                console.log(this.fileList)
            })
            .catch(err => {
                this.fileList.splice(index,1);
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        beforeRead(file) {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                Toast('只允许上传jpg/png格式的图片！');
                return false;
            }
            return true;
        },
        onOversize(file) {
            console.log(file);
            Toast('图片大小不能超过 10M');
        },
        }
    }
</script>
<style scoped lang="scss">
    ::v-deep{
        .van-icon-star{font-size: 0.5rem!important;}
        .van-uploader__upload, .van-uploader__preview-image{border: 2px solid #eee;border-radius: 0.2rem;}
        .Scenes{
            .van-picker__columns{display:none;}
        }
    }
    .form .bg-col{
        background-color: #fff;
    }
    .dy-text-box{
        padding: 0.3rem;
        font-size: 0.3rem;
    }
    .dy-text-box input{width: 100%;}
    .dy-text-box textarea{
        height: 4rem;width: 100%;
    }
    .linename{
        display: block;
        border-bottom: 1px solid #eee;
        margin: 0 0.3rem;
    }
    .btn{display: flex;flex-wrap: wrap;height: 1.6rem;}
    .btn .anniu{flex-direction:row;justify-content:space-evenly;margin-top: 0.3rem;width: 100%;}
    .btn .anniu span{background-color: #f00;color: #fff;border-radius: 0.4rem 0.4rem;padding:0.2rem 1.5rem ;}
    .product_uploader{padding:0.3rem;}
</style>